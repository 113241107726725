/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import { useState } from 'react';
import { Button } from 'rebass';
import ReactMarkdown from 'react-markdown';
import {
  Input as RebassInput,
  InputProps,
  Label as RebassLabel,
  LabelProps,
} from '@rebass/forms';
import { H1, P } from '../components/Text';
import { Program } from '../data/program/program';
import MainLayout from '../layouts/MainLayout';

const Input: React.FC<Omit<InputProps, 'css'>> = ({ ...rest }) => {
  return (
    <RebassInput
      {...rest}
      sx={{
        borderRadius: '4px',
        fontFamily: 'body',
        borderColor: 'text',
        '::placeholder': { color: 'text' },
        height: ['32px', '48px'],
        lineHeight: ['32px', '48px'],
        mb: 3,
      }}
    />
  );
};

const TextArea: React.FC<React.ComponentPropsWithRef<'textarea'>> = ({
  ...rest
}) => {
  return (
    <textarea
      {...rest}
      sx={{
        borderRadius: '4px',
        fontFamily: 'body',
        borderColor: 'text',
        width: '100%',
        bg: 'transparent',
        color: 'text',
        '::placeholder': { color: 'text' },
        mb: 3,
      }}
    />
  );
};

const Label: React.FC<Omit<LabelProps, 'css'>> = ({ ...rest }) => {
  return (
    <RebassLabel
      {...rest}
      sx={{
        fontFamily: 'body',
        fontSize: '2',
        mb: 2,
      }}
    />
  );
};

interface ProgramOverviewProps {
  pageContext: { program: Program };
}
const EditProgramTemplate: React.FC<ProgramOverviewProps> = ({
  pageContext: { program },
}) => {
  const [status, setStatus] = useState('init');
  return (
    <MainLayout>
      <Box sx={{ ml: 3 }}>
        <H1>Edit Talk</H1>
        <form
          sx={{ border: '1px solid', borderColor: 'text', p: 4, mr: 5 }}
          onSubmit={async event => {
            event.preventDefault();
            function getElementValue(key: string) {
              return (event.currentTarget.elements.namedItem(
                key,
              ) as HTMLInputElement)?.value;
            }

            const title = getElementValue('title');
            const resume = getElementValue('resume');
            const description = getElementValue('description');
            const target_audience = getElementValue('target_audience');
            const outline = getElementValue('outline');

            const speaker_1_name = getElementValue('speaker_1_name');
            const speaker_1_function = getElementValue('speaker_1_function');
            const speaker_1_function_fr = getElementValue(
              'speaker_1_function_fr',
            );
            const speaker_1_bio = getElementValue('speaker_1_bio');
            const speaker_1_bio_fr = getElementValue('speaker_1_bio_fr');
            const speaker_1_link = getElementValue('speaker_1_link');
            const speaker_1_twitter = getElementValue('speaker_1_twitter');

            const speaker_2_name = getElementValue('speaker_2_name');
            const speaker_2_function = getElementValue('speaker_2_function');
            const speaker_2_function_fr = getElementValue(
              'speaker_2_function_fr',
            );
            const speaker_2_bio = getElementValue('speaker_2_bio');
            const speaker_2_bio_fr = getElementValue('speaker_2_bio_fr');
            const speaker_2_link = getElementValue('speaker_2_link');
            const speaker_2_twitter = getElementValue('speaker_2_twitter');

            const pwd = getElementValue('pwd');

            const formData = {
              title,
              resume,
              description,
              target_audience,
              outline,
              speaker_1_name,
              speaker_1_function,
              speaker_1_function_fr,
              speaker_1_bio,
              speaker_1_bio_fr,
              speaker_1_link,
              speaker_1_twitter,
              speaker_2_name,
              speaker_2_function,
              speaker_2_function_fr,
              speaker_2_bio,
              speaker_2_bio_fr,
              speaker_2_link,
              speaker_2_twitter,
            };

            const payload = {
              pwd,
              secret: 'not_used',
              formData,
            };

            setStatus('loading');
            const response = await fetch(
              'https://immafridge.api.stdlib.com/ixda21-api@dev/edit-event/',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              },
            );

            const json = await response.json();
            if (json.error) {
              setStatus('error');
            } else {
              setStatus('success');
            }
          }}
        >
          <div>
            <Label>Title</Label>
            <Input
              id="title"
              name="title"
              type="text"
              defaultValue={program.title}
            />
          </div>

          <div>
            <Label>Summary</Label>
            <TextArea
              rows={6}
              id="resume"
              name="resume"
              defaultValue={program.resume}
            />
          </div>

          <div>
            <Label>Description (markdown supported)</Label>
            <TextArea
              rows={6}
              id="description"
              name="description"
              defaultValue={program.description}
            />
          </div>

          <div>
            <Label>Target Audience</Label>
            <Input
              id="target_audience"
              name="target_audience"
              type="text"
              defaultValue={program.target_audience}
            />
          </div>

          <div sx={{ mb: 5 }}>
            <Label>Outline</Label>
            <TextArea
              rows={6}
              id="outline"
              name="outline"
              defaultValue={program.outline}
            />
          </div>

          <Flex sx={{ width: '70%' }}>
            <div sx={{ flex: 1, mr: 6 }}>
              <div>
                <Label>Speaker Name</Label>
                <Input
                  id="speaker_1_name"
                  name="speaker_1_name"
                  type="text"
                  defaultValue={program.speaker_1_name}
                />
              </div>

              <div>
                <Label>Speaker Function</Label>
                <Input
                  id="speaker_1_function"
                  name="speaker_1_function"
                  type="text"
                  defaultValue={program.speaker_1_function}
                />
              </div>

              <div>
                <Label>Speaker Function (FR)</Label>
                <Input
                  id="speaker_1_function_fr"
                  name="speaker_1_function_fr"
                  type="text"
                  defaultValue={program.speaker_1_function_fr}
                />
              </div>

              <div>
                <Label>Speaker Bio</Label>
                <Input
                  id="speaker_1_bio"
                  name="speaker_1_bio"
                  type="text"
                  defaultValue={program.speaker_1_bio}
                />
              </div>

              <div>
                <Label>Speaker Bio (FR)</Label>
                <Input
                  id="speaker_1_bio_fr"
                  name="speaker_1_bio_fr"
                  type="text"
                  defaultValue={program.speaker_1_bio_fr}
                />
              </div>

              <div>
                <Label>Speaker Link</Label>
                <Input
                  id="speaker_1_link"
                  name="speaker_1_link"
                  type="text"
                  defaultValue={program.speaker_1_link}
                />
              </div>

              <div>
                <Label>Speaker Twitter</Label>
                <Input
                  id="speaker_1_twitter"
                  name="speaker_1_twitter"
                  type="text"
                  defaultValue={program.speaker_1_twitter}
                />
              </div>
            </div>

            <div sx={{ flex: 1 }}>
              <div>
                <Label>Secondary Speaker Name</Label>
                <Input
                  id="speaker_2_name"
                  name="speaker_2_name"
                  type="text"
                  defaultValue={program.speaker_2_name}
                />
              </div>

              <div>
                <Label>Secondary Speaker Function</Label>
                <Input
                  id="speaker_2_function"
                  name="speaker_2_function"
                  type="text"
                  defaultValue={program.speaker_2_function}
                />
              </div>

              <div>
                <Label>Secondary Speaker Function (FR)</Label>
                <Input
                  id="speaker_2_function_fr"
                  name="speaker_2_function_fr"
                  type="text"
                  defaultValue={program.speaker_2_function_fr}
                />
              </div>

              <div>
                <Label>Secondary Speaker Bio</Label>
                <Input
                  id="speaker_2_bio"
                  name="speaker_2_bio"
                  type="text"
                  defaultValue={program.speaker_2_bio}
                />
              </div>

              <div>
                <Label>Secondary Speaker Bio (FR)</Label>
                <Input
                  id="speaker_2_bio_fr"
                  name="speaker_2_bio_fr"
                  type="text"
                  defaultValue={program.speaker_2_bio_fr}
                />
              </div>

              <div>
                <Label>Secondary Speaker Link</Label>
                <Input
                  id="speaker_2_link"
                  name="speaker_2_link"
                  type="text"
                  defaultValue={program.speaker_2_link}
                />
              </div>

              <div>
                <Label>Secondary Speaker Twitter</Label>
                <Input
                  id="speaker_2_twitter"
                  name="speaker_2_twitter"
                  type="text"
                  defaultValue={program.speaker_2_twitter}
                />
              </div>
            </div>
          </Flex>
          <div sx={{ width: '25%' }}>
            <Label>Password*</Label>
            <Input id="pwd" name="pwd" required />
          </div>
          <Button type="submit" disabled={status === 'loading'}>
            {status === 'loading' ? 'Sending...' : 'Update Talk'}
          </Button>
        </form>
      </Box>

      {status === 'error' && (
        <P>
          There was a problem trying to save your changes. Did you ensure that
          the password is valid?
        </P>
      )}
      {status === 'success' && (
        <P>
          All good! Changes may take up to 24 hours before being reflected on
          the website.
        </P>
      )}
    </MainLayout>
  );
};

export default EditProgramTemplate;
